import { businessId } from '@/helper/env'
import { useCommonStore } from '@/store/common'
import { useLayoutStore } from '@/store/layout'
import { useUserStore } from '@/store/user'
import { t } from '@lingui/macro'
import { useMemo } from 'react'

export function useGetCustomerJumpUrl() {
  const { customerJumpUrl, imgWebLogo } = useLayoutStore().layoutProps || {}
  const { userInfo, deviceId } = useUserStore()
  const { localeInfo } = useCommonStore()
  const {
    customerService: { paramsList = null, hasUrlParams = false, businessId: customerBId = '', groupid = '' } = {},
  } = localeInfo || {}

  const customerUrl = useMemo(() => {
    if (!customerJumpUrl) return ''
    let url = customerJumpUrl

    if (paramsList) {
      const defaultParams = {
        uid: userInfo?.uid || deviceId,
        nickName: userInfo?.nickName || t`features_home_floating_icon_button_index_r1unrgo4dn`,
        avatarPath: userInfo?.avatarPath || imgWebLogo,
        groupid: groupid || businessId,
        businessId: customerBId || businessId,
      }

      const queryParams: string[] = []

      for (const key in paramsList) {
        // eslint-disable-next-line no-prototype-builtins
        if (paramsList.hasOwnProperty(key)) {
          const paramKey = paramsList[key]
          const paramValue = encodeURIComponent(defaultParams[key] || userInfo[key])
          queryParams.push(`${paramKey}=${paramValue}`)
        }
      }

      url = `${url}${hasUrlParams ? '&' : '?'}${queryParams.join('&')}`
    }

    return url
  }, [customerJumpUrl, paramsList, userInfo?.uid])

  return customerUrl
}
